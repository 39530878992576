
import { Component, Mixins } from "vue-property-decorator";
import { EntityType } from "@/models/schoolfinder.enums";
import SchoolMixin from "@/mixins/school.mixin";
import CommonMixin from "@/mixins/common.mixin";
import ScrollToTopMixin from "@/mixins/scroll-to-top.mixin";
import SchoolOverallLetterGrade from "@/components/Application/SchoolAcademicPerformance/Common/SchoolOverallLetterGrade.vue";
@Component({
  components: {
    "school-overall-letter-grade": SchoolOverallLetterGrade,
  },
})
export default class SchoolPerformance extends Mixins(
  SchoolMixin,
  ScrollToTopMixin,
  CommonMixin
) {
  EntityType = EntityType;

  get schoolPerformance() {
    return this.school && this.school.schoolPerformance;
  }

  get sections() {
    return [
      {
        id: 1,
        section: this.schoolPerformanceOnMetrics,
        subSections: [
          {
            id: 1,
            header: "Student Performance",
            studentPerformance: [
              {
                id: 1,
                label: "Assessments",
                value:
                  this.schoolPerformance &&
                  this.schoolPerformance.studentAssessmentGrade,
              },
              {
                id: 2,
                label: "ACT",
                value:
                  this.schoolPerformance && this.schoolPerformance.actGrade,
              },
              {
                id: 3,
                label: "Graduation",
                value:
                  this.schoolPerformance &&
                  this.schoolPerformance.graduationGrade,
              },
              {
                id: 4,
                label: "Strength of Diploma",
                value:
                  this.schoolPerformance &&
                  this.schoolPerformance.strengthOfDiplomaGrade,
              },
            ],
            externalLink: "student_performance",
            buttonLabel: "Read about Student Performance ",
          },
          {
            id: 2,
            header: "Student Progress",
            studentPerformance: [
              {
                id: 1,
                label: "Progress Rating",
                value:
                  this.schoolPerformance &&
                  this.schoolPerformance.studentProgressGrade,
              },
            ],
            externalLink: "student_progress",
            buttonLabel: "Read about Student Progress ",
          },
        ],
      },
      {
        id: 2,
        section: this.schoolPerformanceOtherAreas,
        subSections: [
          {
            id: 1,
            header: "Overall Performance",
            showSchoolOverallPerformance: true,
            externalLink: "overall-performance",
            buttonLabel: "Read about Overall Performance ",
          },
          {
            id: 2,
            header: "Breakdown by Student Groups",
            instruction:
              `The breakdown by student groups provides information on how well a school ${this.schoolSystem ? 'system ' : ''}is serving all of its students, as well as the demographic makeup of the school.`,
            externalLink: "breakdown_student_groups",
            buttonLabel: "Read about Breakdown by Student Groups ",
          },
          {
            id: 3,
            header: "Teacher Workforce",
            instruction:
              `Teacher workforce provides information on the ${this.schoolSystem ? 'school system’s' : 'school’s'} teacher workforce, including teacher qualifications, teacher diversity, and the extent to which teachers choose to continue teaching at the ${this.schoolSystem ? 'school system' : 'school'} year to year.`,
            externalLink: "teacherworkforce",
            buttonLabel: "Read about Teacher Workforce ",
          },
        ],
      },
    ];
  }

  get schoolPerformanceOnMetrics() {
    let result;
    switch (this.entityType) {
      case EntityType.School:
        result = "How did the school perform on performance score metrics in " + this.overallPerformance.schoolCurrentYear.letterGradeYearFormatted + "?";
        break;
      case EntityType.SchoolSystem:
        result =
          "How is the school system performing on performance score metrics?";
        break;
      default:
        break;
    }
    return result;
  }

  get schoolPerformanceOtherAreas() {
    let result;
    switch (this.entityType) {
      case EntityType.School:
        result = "How did the school perform in other areas?";
        break;
      case EntityType.SchoolSystem:
        result = "How is the school system performing in other areas?";
        break;
      default:
        break;
    }
    return result;
  }
}
