
import { Component, Mixins, Prop } from "vue-property-decorator";
import SchoolMixin from "@/mixins/school.mixin";
import CommonMixin from "@/mixins/common.mixin";
import { OverallLetterGrade } from "@/models/interface";
@Component
export default class SchoolOverallLetterGrade extends Mixins(
  SchoolMixin,
  CommonMixin
) {
  @Prop({ default: false }) showLetterGrade!: boolean;
  @Prop({ default: false }) showSPS!: boolean;
  @Prop({ default: false }) compareSchoolSystem!: boolean;

  @Prop() overallLetterGrade!: OverallLetterGrade;

  @Prop({ default: "" }) labelValue!: string;

  /* TODO: Fix this */
  get formattedString() {
    let result;
    if ((this.showLetterGrade && this.showSPS) || this.showLetterGrade)
      result = "Letter Grade";
    else if (this.showSPS) result = "School Performance Score";
    else if (this.compareSchoolSystem) result = "Overall School Performance";
    return result;
  }

  get customAlignment() {
    return {
      "align-items-center": !this.compareSchoolSystem,
    };
  }
}
