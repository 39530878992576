var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',_vm._l((_vm.sections),function({ id, section, subSections }){return _c('section',{key:id},[_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col"},[_c('h2',{staticClass:"doe-dark-blue pb-4"},[_vm._v(" "+_vm._s(section)+" ")])])]),_c('div',{staticClass:"row row-cols-1 row-cols-md-2 no-gutters"},_vm._l((subSections),function({
            id,
            header,
            studentPerformance,
            showSchoolOverallPerformance,
            instruction,
            externalLink,
            buttonLabel,
          },index){return _c('div',{key:id,class:[index % 2 === 0 ? 'pr-md-3' : 'pl-md-3', 'col', 'mb-4']},[_c('div',{staticClass:"card mb-0 shadow-none"},[_c('div',{staticClass:"row ml-0 mr-0"},[_c('div',{staticClass:"col h5 p-4 doe-dark-blue"},[_vm._v(" "+_vm._s(header)+" ")])]),_c('div',{staticClass:"h-80"},[(showSchoolOverallPerformance)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col mb-4"},[_c('school-overall-letter-grade',{attrs:{"overall-letter-grade":_vm.overallPerformance && _vm.overallPerformance.schoolCurrentYear,"showLetterGrade":true,"showSPS":true,"label-value":"Letter Grade"}})],1)]):_vm._e(),(studentPerformance)?_c('div',{staticClass:"row row-cols-1 row-cols-md-2"},_vm._l((studentPerformance),function({ id, label, value }){return _c('div',{key:id,staticClass:"col mb-2"},[_c('div',{staticClass:"d-flex justify-content-start align-items-center"},[_c('div',{staticClass:"circle-sm mr-2",class:_vm.setGradeBadgeByRating(value)},[_c('span',{class:value != 'N/A' ? 'circle-sm-font' : ''},[_vm._v(_vm._s(value))])]),_c('span',{staticClass:"doe-dark-blue ml-1"},[_vm._v(_vm._s(label))])])])}),0):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[(instruction)?_c('p',{staticClass:"doe-dark-blue"},[_vm._v(" "+_vm._s(instruction)+" ")]):_vm._e()])])]),_c('div',{staticClass:"row border-top text-center ml-0 mr-0 fix-height-54"},[_c('div',{staticClass:"col align-self-center px-0"},[_c('span',{staticClass:"doe-links-darker",attrs:{"role":"button"},on:{"click":function($event){_vm.scrollToTop();
                  _vm.$router.push(
                    `/schools/${_vm.$route.params.schoolId}/academic-performance/${externalLink}`
                  );}}},[_c('span',{},[_vm._v(_vm._s(buttonLabel))]),_c('i',{staticClass:"fas fa-arrow-right fa-fw"})])])])])])}),0),(id !== _vm.sections.length)?_c('hr',{staticClass:"major bg-doe-secondary"}):_vm._e()])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }