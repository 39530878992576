
import { Vue, Component } from "vue-property-decorator";
import SchoolPerformance from "@/components/Application/SchoolAcademicPerformance/Common/SchoolPerformance.vue";
@Component({
  components: {
    "school-performance": SchoolPerformance,
  },
  metaInfo: {
    title: "Academic Performance",
  },
})
export default class SchoolAcademicPerformance extends Vue {}
